








































































import Vue from 'vue';
export default Vue.extend({
  name: 'projectList',
  props: {
    guideVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      states: 1,
      loading: false,
    };
  },
  methods: {
    /**
     * @description: 右侧切换按钮
     * @param {*}
     * @return {*}
     */
    stepChange(step: number) {
      this.states = step;
    },
    /**
     * @description: 下一步按钮
     * @param {*}
     * @return {*}
     */
    next() {
      if (this.states < 6) {
        this.states += 1;
      } else {
        this.guideClose();
      }
    },
    /**
     * @description: 关闭操作指引页面
     * @param {*}
     * @return {*}
     */
    guideClose() {
      this.states = 1;
      this.$emit('update:guideVisible', false);
    },
  },
});
